@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap');

.App {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
}

.App-body {
  background: linear-gradient(90deg, rgba(248,109,201,1) 0%, rgba(115,217,250,1) 100%);
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

