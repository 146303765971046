.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 80%;
    min-height: 100vh;
}

.profile-picture {
    margin-top: 10%;
    margin-bottom: 5px;
}

.profile-picture img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 4px solid rgb(255, 255, 255);
    background: rgba(255, 255, 255, 0.46);
}

.profile-description {
    text-align: center;
    margin-bottom: 10px;
}

.profile-description p {
    font-size: 1.25rem;
    font-weight: 500;
}

.container {
    border: 5px solid rgb(255, 255, 255);
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.151);
    padding: 20px !important;
}

.profile-description ul {
    list-style: none;
    padding: 0;
}

#skill-list-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 35px;
    text-decoration: none;
}

ul ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

ul h2 {
    text-decoration: underline;
}

ul li {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 10px;
    margin-right: 5px;
    margin-left: 5px;
}

/* Shrink content for small devices */
@media only screen and (max-width: 600px) {
    .home {
        margin-top: 50px;
    }

    .profile-picture img {
        width: 150px;
        height: 150px;
    }

    .profile-description p {
        font-size: 1rem;
    }

    .container {
        border: 2px solid rgb(255, 255, 255);
        border-radius: 15px;
        background: rgba(0, 0, 0, 0.151);
        padding: 10px !important;
    }

    #skill-list-title {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 15px;
        text-decoration: none;
    }

    ul li {
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 1px;
        margin-right: 5px;
        margin-left: 5px;
    }
}